.filterSection {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 50px;
  position: fixed;
  top: 50px;
  z-index: 1;
  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.filterSection > button {
  height: 70%;
  background-color: white;
  border: var(--border-default);
  border-radius: var(--border-radius);

  font-size: 16px;
  color: var(--primary-text-color);
  font-weight: 500;
}

.traderListingMainContainer {
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.listingMain {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.listingDetailsMain {
  /* height: 100px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.detailsSectionMain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.detailsTexts {
  display: flex;
  align-items: center;
  gap: 5px;
}

.listingNameText {
  color: var(--primary-text-color);
  font-weight: 600;
  font-size: 16px;
}

.detailsTexts > div:nth-child(1) {
  color: var(--primary-text-color);

  font-weight: 500;
  font-size: 16px;
}

.detailsTexts > div:nth-child(2) {
  color: var(--seondary-text-color);
  font-weight: 500;
  font-size: 14px;
}

.detailsMainCont {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.containerDetailsSection {
  display: flex;
}

.eachDetailsContainer {
  width: 50%;
}

.eachDetailsContainer > tr > td:nth-child(1) {
  font-weight: 500;
  color: var(--seondary-text-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 3px;
}
.eachDetailsContainer > tr > td:nth-child(3) {
  color: var(--primary-text-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 3px;
}

.agreementBtn {
  background-color: var(--brand-text-color);
  padding: 10px;
  border-radius: var(--border-radius);
  color: #ffffff;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin: auto;
}
.agreementBtn:hover {
  background-color: rebeccapurple;
  padding: 10px;
  border-radius: var(--border-radius);
  color: #ffffff;
  cursor: pointer;
}

.alterArea {
  /* justify-content: space-between; */
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
}

.expTimerSection {
  display: flex;
  padding: 0px 5px;
}

.singleDetailsMainCont {
  display: flex;
}

.consumerSection,
.generatorSection {
  width: 50%;
  padding: 1%;
}

.consumerSection {
}

.detailsHeader {
  background-color: #ff7d00;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.tableContainer {
  border-collapse: collapse;
  width: 100%;
}

.tableCOntents {
  border: 1px solid black;
  width: 50%;
  height: 40px;
}
.tableRow {
  width: 100%;
}

.eachSingleSelectionContainer {
  border: var(--normal-border);
  border-radius: var(--border-radius);
  padding: 10px;
  margin-bottom: 5px;
  background-color: rgba(243, 178, 140, 0.1);
}

@media screen and (max-width: 1000px) {
  .containerDetailsSection {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .eachDetailsContainer {
    width: 100%;
  }
}
