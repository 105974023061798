.dashboardMainContainer {
  display: flex;
  gap: 1.6%;
}

.dashboardLeftSection {
  width: 19.4%;
  height: 100vh;
  background-color: var(--normal-bg-color);
}

.dashboardRightContent {
  width: 79%;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.navContainer {
  height: 100px;
}

.dashboardDetailsSection {
  height: calc(99vh - 100px - 20px);
  overflow-y: scroll;
}

