.editModalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: 0px;
  width: 60vw;
  border-radius: var(--border-radius);
  background-color: #fff;

  height: auto;
}
.editLayerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 26px;
  padding: 20px;
}

.editHead {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px;
  border-bottom: var(--normal-border);
}

.editName {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--primary-text-color);
}

.editLayerFirst {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.eachElementLayerFirst {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 33.33%;
}

.elementHeading {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  color: var(--seondary-text-color);
}

.nonEditData {
  width: calc(100% - 24px);
  background-color: #d4d4d4;
  height: 48px;
  border: var(--border-radius);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  color: var(--seondary-text-color);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 24px;
  cursor: no-drop;
}

.editLayerSecond {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.layerSectionSecond {
  width: 50%;
  gap: 2%;
  display: flex;
}

.eachElementL2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input[type="date"],
input[type="time"] {
  width: 95%;
  height: 48px;
  color: var(--primary-text-color);
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 5px;
  border: var(--normal-border);
  padding-left: 5%;
  background-color: #fafafa;
}

::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}

.energyLevelThree {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.eachEnergyElement {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.textInput {
  width: 95%;
  height: 48px;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: 0px !important;
  color: var(--seondary-text-color) !important;
  border: none;
  outline: none;
  border-radius: 5px;
  border: var(--normal-border);
  padding-left: 5%;
  background-color: #fafafa;
}

.updateBtn {
  height: 56px;
  background-color: var(--brand-bg-color);
  width: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  color: #fff;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.inputAreasTimeDate {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: 0px !important;
  color: var(--seondary-text-color) !important;
}
