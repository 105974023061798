.loginMainContainer {
  display: flex;
  height: 99.7vh;
}

.loginAlterSection {
  width: 35%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 112px;
}

.loginImageSection {
  width: 65%;
  border: 1px solid;
  background-color: var(--secondary-brand-color);
}

.gnaFoterText {
  width: 60%;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  color: var(--seondary-text-color);
}

.connectSectionMain {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 60%;
}

.heardertext01 {
  display: flex;
  flex-direction: column;
}

.boldHead {
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primary-text-color);
}

.secondaryText {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--seondary-text-color);
}

.signInButton {
  width: 100%;
  height: 48px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--brand-bg-color);
  border-radius: var(--border-radius);

  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}

.forgotPass {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  font-weight: 600;
  color: var(--secondary-brand-color);
  text-align: center;
}

.inputSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.inputSectionMain {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sectionHeading {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--primary-text-color);
  font-weight: 500;
}

.iconContainer {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userNameInputSec {
  height: 48px;
  border: var(--normal-border);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
}

.userInputSection {
  height: 90%;
  width: 90%;
  border: 0px;
  border-radius: var(--border-radius);
  background-color: transparent;
}

.userInputSection:focus-visible {
  border: 0px;
  outline: 0px;
  border-radius: var(--border-radius);
}

.passwordInputSection {
  height: 90%;
  width: 80%;
  border: 0px;
  border-radius: var(--border-radius);
  background-color: transparent;
}

.passwordInputSection:focus-visible {
  border: 0px;
  outline: 0px;
  border-radius: var(--border-radius);
  background: transparent;
}

.viewSection {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
}
