/* #chat-window{
    height: 20rem;
}
#chat-window-messages{
    height: 18rem;
    overflow: auto;
} */
#chat-window{
    height: 20rem;
}
#chat-window-messages{
    height: 18rem;
    overflow: auto;
    font-family: "Sofia Sans", sans-serif;
}
.custom-send-icon{
    color: #ff7d00;
}
.message-wrapper.others-message{
    padding-left: 0px;
    padding-right: 20px;
}
.message-wrapper.self-message{
    padding-left: 20px;
    padding-right: 0px;  
}
.user-msg{
    padding: 10px;
}
.user-msg.self{

}
.user-msg.others{
    background-color: #ff7d00;
    color: #ffffff;
}
.user-name{
    color: #434b4c;
}