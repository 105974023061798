.listingContainer {
  width: 85%;
  padding: 1.5%;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: var(--border-radius);
}

.dataCOntainer {
  display: flex;
  gap: 10%;
}

.eeachListCOntainer {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.listDetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.detislLOne {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detHeading {
  display: flex;
  gap: 10px;
  align-items: center;

  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #9f9f9f;
}

.detValue {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #333333;
}

.detailsHeadingText {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #333333;
}

.alterSec {
  width: 100%;
  display: flex;
  gap: 10%;
}

.statusBar {
  width: 60%;
}

.buttonSec {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.viewAgrBtn {
  height: 54px;
  width: 245px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #333333;
  border: 1px solid #f1935c;
  background: transparent;
  border-radius: var(--border-radius);
  cursor: pointer;
}
