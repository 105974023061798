.leftSectionContainer {
  display: flex;
  flex-direction: column;
  gap: 64px;

  padding: 32px;
}

.contentSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.accodianMain {
  display: flex;
  gap: 10px;
  align-items: center;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

.accodianMain1 {
  display: flex;
  gap: 10px;
  align-items: center;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--brand-text-color);
}

.sectionDetails {
  margin-top: -10px;
  padding-left: 10%;

  display: flex;
  flex-direction: column;
  gap: 8px;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  cursor: pointer;
}

.activeType {
  color: var(--brand-text-color);
}
