:root {
  --primary-brand-color: #f1935c;
  --secondary-brand-color: #28565d;
  /* global style font color */
  --primary-text-color: #333333;
  --seondary-text-color: #9f9f9f;
  --brand-text-color: #f1935c;
  --dark-text-color: #3f3f3f;
  --black-text-color: #111111;

  /* global style bg color */
  --brand-bg-color: #f1935c;
  --black-bg-color: #111111;
  --normal-bg-color: #f8f8f8;
  --third-bg-color: #f8f8f8;

  --border-radius: 8px;

  --border-default: 1px solid #f1935c;
  --border-active: 1px solid var(var(--secondary-brand-color));
  --normal-border: 1px solid #9f9f9f80;
}

body {
  font-family: "Sofia Sans", sans-serif !important;
  font-weight: 300 !important;
  color: var(--primary-text-color) !important;
  background: linear-gradient(
    to right,
    rgba(241, 147, 92, 0.1),
    rgba(241, 147, 92, 0)
  );
  /* padding-top: 58px;
  padding-bottom: 40px; */
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 5px !important;
}
