.navbarContainer {
  width: 93%;
  height: 100%;
  background-color: var(--normal-bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 4.5% 0% 2.5%;
}

.typeText {
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.rightNavContent {
  display: flex;
  align-items: center;
  gap: 32px;
  cursor: pointer;
}

.accountSection {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dropDownContent {
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 5px;
  border-radius: var(--border-radius);
}

.dropDownContent > div {
  padding: 5px;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.typeBtn {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid #dfeaf2;
  border-radius: var(--border-radius);

  padding: 10px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  cursor: pointer;
}

.searchSection {
  height: 38px;
  width: 334px;
  border: var(--normal-border);
  border-radius: var(--border-radius);
  display: flex;
}

.searchIcon {
  display: flex;
  height: 100%;
  width: 10%;
  align-items: center;
  justify-content: center;
}

.inputSection {
  width: 90%;
  background: transparent;
  border: 0px;
  border-radius: var(--border-radius);
  background-color: transparent;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.inputSection:focus-visible {
  border: 0px;
  outline: 0px;
  border-radius: var(--border-radius);
  background: transparent;
}
