.chatModalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: 0px;
  width: 40vw;
  border-radius: var(--border-radius);
  background-color: #fff;
  min-height: 50vh;
}

.topContain {
  height: 5vh;
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 2%;
  border-bottom: var(--normal-border);

  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--primary-text-color);
  font-weight: 600;
}
.chatSection {
  height: 35vh;
  overflow-y: scroll;
}
.bottonSearchContainer {
  min-height: 10vh;
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 2%;
  border-top: var(--normal-border);

  display: flex;
  gap: 2%;
}

.searchSec {
  width: 90%;
  padding: 2vh 0px;
}

.sendbtn {
  height: 50px;
  width: 10%;
  border: 1px solid #f1935c;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.messageSection {
  padding: 0px 25px;
}

.message_wrapper.others_message {
  padding-left: 0px;
  padding-right: 40px;
}
.message_wrapper.self_message {
  padding-left: 40px;
  padding-right: 0px;
}
