.listingMainContainer {
  padding-bottom: 100px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}
.headerComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: var(--normal-border);
}

.traderListContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.elementLayout {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 37%;
}

.detailsSection {
  width: 63%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4%;
}

.listHeading {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--primary-text-color);
}
.companyName {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--primary-text-color);
}

.closeingDetails {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: var(--seondary-text-color);
}

.detailsHeading {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;

  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: var(--seondary-text-color);
}

.detailValue {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: var(--primary-text-color);
}

.matchListingContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.matchFilterContainer {
  display: flex;
  gap: 100px;
  border-bottom: var(--normal-border);
  padding-bottom: 15px;
}

.eachFilterElemet {
  padding-left: 50px;
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.matchLevelUp {
  display: flex;
  flex-direction: column;
}

/* Matched Listing */

.matchContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: var(--border-radius);
  margin: 0px 32px 32px;
  background-color: #ffffff;
}
.mathListName {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: var(--primary-text-color);
}

.statusSection {
  width: 50%;
}

.matchDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px 24px 16px 24px;
}

.matchInnerComponent {
  display: flex;
}

.buttonSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px 24px;
  gap: 24px;
  border-top: 1px solid #dfeaf2;
}
.buttonSection > div {
  cursor: pointer;
  min-width: 224px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f6f6f6;
  border-radius: var(--border-radius);

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  color: var(--primary-text-color);
  font-weight: 500;
}

.withdrawText {
  border: 1px solid #ef4444;
  background-color: #ffffff !important;
}

.agreementbtn {
  background-color: var(--primary-brand-color) !important;
  color: #fff !important;
}
/* Matched Listing */
