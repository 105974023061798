.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: 0px;
  height: auto;
  width: 60vw;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.termsFooter {
  height: 54px;
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
}

.checkboxSection {
  display: flex;
  align-items: center;
  gap: 16px;

  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: var(--seondary-text-color);
  cursor: pointer;
}

.viewAgreement {
  height: 54px;
  width: 245px;
  background-color: var(--seondary-text-color);
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: no-drop;
}

.viewAgreementTrue {
  height: 54px;
  width: 245px;
  background-color: var(--brand-bg-color);
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bidDetails {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 10px 32px;
}

.detailsMain {
  display: flex;
  flex-direction: column;
}

.detailsHeader {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 8px 8px 0px 0px;
}

.detailsHeader > div {
  height: 40px;
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--primary-text-color);
}

.detailsData {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: var(--normal-border);
}

.detailsData > div {
  width: 33.33%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: var(--primary-text-color);
}

.detailsSection {
  border-collapse: collapse;
}

.detailsSection > tr {
  display: flex;
  width: 100%;
}

.detailsSection > tr > th {
  width: 33.33%;
  height: 48px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  background-color: var(--normal-bg-color);
  border: var(--normal-border);
  display: flex;
  justify-content: center;
  align-items: center;
}

.detailsHeading {
  background-color: var(--normal-bg-color) !important;
  height: 56px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-text-color);
  border-radius: 8px 0px 0px 0px;
}
.buyerHeading {
  background-color: var(--primary-brand-color) !important;
  height: 56px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.sellerHeading {
  background-color: var(--secondary-brand-color) !important;
  height: 56px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 0px 8px 0px 0px;
}

.agreementHead {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 32px;
  border-bottom: var(--normal-border);
}

.popHead {
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
}

.fileUpload {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.btnSection {
  display: flex;
  gap: 16px;
}

.updateHeading {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--primary-text-color);
}

.rejectBtn {
  height: 54px;
  width: 180px;
  background-color: transparent;
  color: var(--primary-text-color);
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #ef4444;
}

.inputFile {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 54px;
  width: 238px;
  border: var(--normal-border);
  border-radius: var(--border-radius);
}

.inputFile > input[type="file"] {
  display: none;
}
.inputFile1 > input[type="file"] {
  display: block;
}

.inputLabel {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}

.editContainer {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.agrView {
  display: flex;
  align-items: center;
  gap: 20px;
}
